import React, {useEffect, useMemo, useState} from 'react';
import {Badge, Divider, Drawer as MuiDrawer, styled} from '@mui/material';
import {Dataset, HistoryRounded, SubtitlesOutlined} from '@mui/icons-material';
import {useContext} from 'react';
import {SidebarContext} from './context/sidebar.context';
import useAuthorized from '../../../hooks/use-authorized.hook';
import SidebarLinks from './sidebar-links';
import RouteEnum from '../../../router/enums/route.enum';
import {Cloud} from "../../ui/icon";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {getCounts} from "../../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {selectCounts} from "../../../store/selectors";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2, 3),
  ...theme.mixins.toolbar,
}));

const Sidebar = () => {
  const sidebar = useContext(SidebarContext);
  const counts = useSelector(selectCounts)
  const isAuthorised = useAuthorized();
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCounts());
  }, []);

  useEffect(() => {
    dispatch(getCounts());
  }, [location]);

  const sidebarLinks = useMemo(() => [
      {
        label: t('uploading-files.title'),
        link: RouteEnum.UPLOADING_FILES,
        Icon: Cloud,
        count: counts.uploaded_counter,
      },
      {
        label: t('parsed-files.title'),
        link: RouteEnum.PARSED_FILES,
        Icon: SubtitlesOutlined,
        count: counts.confirmed_counter,
      },
      {
        label: t('parsed-files-validation.title'),
        link: RouteEnum.PARSED_FILES_VALIDATION,
        Icon: SubtitlesOutlined,
        count: counts.validated_counter,
      },
      {
        label: t('files-history.title'),
        link: RouteEnum.FILES_HISTORY,
        Icon: HistoryRounded,
        count: counts.history_counter,
      },
    ], [t, counts]);

  if (!isAuthorised) return null;

  return (
    <Drawer variant="permanent" open={sidebar.opened}>
      <DrawerHeader>
        <img src="/img/logo.png" width={120}/>
      </DrawerHeader>
      <SidebarLinks links={sidebarLinks}/>
    </Drawer>
  );
};

export default Sidebar;
