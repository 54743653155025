import { call, put } from 'redux-saga/effects';
import { AuthApi } from '../../services/api/auth';
import {
  getCountsError,
  getCountsSuccess, getHpError, getHpSuccess,
  logoutError,
  logoutSuccess,
  signInError,
  signInSuccess
} from '../actions/auth';
import { AuthStorage } from '../../services/storage/auth.storage';
import {FileApi} from "../../services/api/file";

export function* signIn({ payload, meta }) {
  try {
    const data = yield call(AuthApi.signIn, payload);
    yield call(AuthStorage.storeAccessToken, data.access);
    yield call(AuthStorage.storeRefreshToken, data.refresh);

    const authData = yield call(AuthApi.getAuthUser);
    yield put(signInSuccess({ authData }));
    yield call(AuthStorage.storeUser, authData);
    yield call(meta.onSuccess);
    yield call(() => {
      window.location.reload();
    });
  } catch (error) {
    console.log([error]);
    yield put(signInError(error));
    meta.onError(error?.response?.data || error?.data);
  }
}

export function* logout() {
  try {
    yield put(logoutSuccess());
    yield call(AuthStorage.removeUser);
    yield call(AuthStorage.removeAccessToken);
    yield call(AuthStorage.removeRefreshToken);
    yield call(() => {
      window.location.reload();
    });
  } catch (error) {
    console.log([error]);
    yield put(logoutError(error));
  }
}

export function* getCounts() {
  try {
    const res = yield call(FileApi.getFilesCount);
    yield put(getCountsSuccess(res));
  } catch (error) {
    console.log([error]);
    yield put(getCountsError(error));
  }
}

export function* getHp() {
  try {
    const res = yield call(FileApi.getHazardPoints);
    const result = [];
    
    for (const item of res) {
      if (result.find(({ h_punto }) => h_punto === item.h_punto)) continue;
      
      if (item.categoria_di_pericolo) {
        result.push({
          ...item,
          h_punto: `${item.h_punto} ${item.categoria_di_pericolo}`
        });
        continue;
      }

      result.push(item);
    }
    
    yield put(getHpSuccess(result));
  } catch (error) {
    console.log([error]);
    yield put(getHpError(error));
  }
}
