import React from "react";
import {Box, Typography, Container, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  
  return (
    <Box component="footer" sx={{
      py: 3,
      px: 2,
      mt: "auto",
      textAlign: "center"
    }}>
      <Container sx={{ p: 2 }} maxWidth="md">
        <Typography variant="body2" color="textSecondary">
          {t('footer.note')}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          &copy; {new Date().getFullYear()} Kaimakiweb.com. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
